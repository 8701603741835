<template>
  <div class="echarts">
    <div ref="dotMap" class="dotMap"></div>

    <div class="map_right">
      <div class="cityList_return">
        <span @click="deep = 0" class="moveTop1">{{ nameStr1 }}</span>
        <span v-show="deep > 0">&nbsp;>&nbsp;</span>
        <span
          :class="deep == 1 ? 'fontbold' : ''"
          @click="deep = 1"
          class="moveTop1"
          >{{ nameStr2 }}</span
        >
        <span v-show="deep > 1">&nbsp;>&nbsp;</span>
        <span :class="deep == 2 ? 'fontbold' : ''" class="moveTop1">{{
          nameStr3
        }}</span>
      </div>
      <div class="cityList" v-if="openDom">
        <el-scrollbar style="height: 100%">
          <div class="city_item" v-for="(item, index) in cityitem" :key="index">
            <!-- @click="toPage(item.url1)" -->
            <div style="width: 350px; height: 150px; overflow: hidden">
              <img :src="item.companyImage" width="100%" />
            </div>
            <div class="cityList_title" v-if="!item.name1">
              {{ item.companyName }}
            </div>
            <!-- <div class="cityList_title" v-if="item.name1">
              {{ item.name1 }} <br />{{ item.name2 }}
            </div> -->
            <div>城市：{{ item.companyLocation }}</div>
            <!-- <div>网站：{{ item.url }}</div> -->
            <div>联系方式：{{ item.phone }}</div>
            <!-- <div>地址：{{ item.addr }}</div> -->
            <div
              style="
                width: 350px;
                height: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              "
            >
              介绍：{{ item.companyDesc }}
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getCompanyList } from "@/api/index.js";
export default {
  props: {
    openDom: {
      typeof: Boolean,
      required: false,
    },
    adminCode: {
      typeof: Number,
      required: false,
    },
    PropsCitylist: {
      typeof: Object,
      required: false,
    },
    fatherCityList: {
      typeof: Object,
      required: false,
    },
  },
  name: "dotMap",
  data() {
    return {
      option: null,
      execute: true,
      color1: "#fffbef",
      Type: 0,
      showtype: false,
      HQ: true,
      myChart: null,
      geoJson: {
        features: [],
      },
      mapData: [],
      dataindexData: {
        id: 0,
      },
      dataindex: 0,
      cityCode: 100000,
      mapDataList: [],
      tootipTimer: "",
      value: [],
      parentInfo: [
        {
          cityName: "全国",
          code: 100000,
        },
      ],
      cityList: [
        {
          companyName: "浙江中峪控股集团有限公司",
          name: "浙江中峪控股集团有限公司",
          companyLocation: "浙江省",
          url: "www.zoneyu.cn",
          url1: "www.zoneyu.cn",
          lxy: [120.50193, 30.0904],
          // value: [120.50193, 30.0904],
          value: [103.764724, 29.550391],
          companyImage:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-15/bdccd6b108294b30b9b06db0a716f6e6.png",
          phone: "400-185-3168",
          addr: "浙江绍兴柯桥区IFC国际金融中心裙楼2F",
          companyDesc:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。",
        },
        // [
        //   {
        //     name: "浙江中峪道地药材有限公司",
        //     city: "浙江省",
        //     url: "www.zoneyu.cc",
        //     url1: "www.zoneyu.cc/#/base?companyId=19&type=0&index=0",
        //     src: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
        //     phone: "400-185-3168",
        //     addr: "浙江绍兴柯桥区IFC国际金融中心裙楼2F",
        //   },
        //   {
        //     name: "浙江中峪药风光能源开发有限公司",
        //     city: "浙江省",
        //     url: "www.zoneyu.net",
        //     url1: "www.zoneyu.cc/#/baseOther?companyId=49&type=0&index=1",
        //     src: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/32d4d2f6c848456da4306426edde15b6.jpeg",
        //     phone: "400-185-3168",
        //     addr: "浙江绍兴柯桥区IFC国际金融中心裙楼2F",
        //   },
        // ],
        // [
        //   {
        //     name1: "中峪道地药材平顶山仿野生白术种",
        //     name2: "植基地",
        //     city: "浙江省",
        //     url: "www.zoneyu.cc",
        //     url1: "www.zoneyu.cc/#/projectDetail?id=101",
        //     src: "https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
        //     phone: "400-185-3168",
        //     addr: "浙江新昌县沃洲镇黄坛村",
        //   },
        // ],
      ],
      cityitem: [
        {
          companyName: "浙江中峪控股集团有限公司",
          name: "浙江中峪控股集团有限公司",
          companyLocation: "浙江省",
          url: "www.zoneyu.cn",
          url1: "www.zoneyu.cn",
          lxy: [112.946257, 28.196695],
          value: [103.764724, 29.550391],
          companyImage:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-15/bdccd6b108294b30b9b06db0a716f6e6.png",
          phone: "400-185-3168",
          addr: "浙江绍兴柯桥区IFC国际金融中心裙楼2F",
          companyDesc:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。",
        },
      ],
      deep: 0,
      nameStr1: "",
      nameStr2: "",
      nameStr3: "",
      onCity: null,
      sonCompany: { current: 1, size: 4, pid: 3 },
      codeList: [],
      codeList1: [],
      codeList2: [],
      param: null,
      Arealist: null,
      AreamapData: null,
      Areaparam: null,
      Areaes: null,
      MapCityId: null,
    };
  },
  mounted() {
    console.log(this.PropsCitylist, this.fatherCityList, "yz");
    if (this.PropsCitylist) {
      this.cityList = [];
      this.PropsCitylist.forEach((e) => {
        console.log(e);
        if (e.adCode) {
          this.cityList.push(e);
        }
      });
      console.log(this.cityList, this.PropsCitylist, "yz");
      this.deep = -1;
      // if (this.PropsCitylist[0].adCode.slice(-4) == "0000") {
      if (this.fatherCityList.adCode) {
        console.log(this.fatherCityList, "this.father");
        // setTimeout(() => {
        //   this.getGeoJson(100000);
        // }, 600);
      } else {
        setTimeout(() => {
          this.getGeoJson(100000);
        }, 600);
      }
      // }
    } else {
      console.log(this.adminCode);
      setTimeout(() => {
        this.getGeoJson(this.adminCode);
      }, 600);
      // this.getGeoJson(this.adminCode);
    }
  },

  async created() {
    localStorage.setItem("cityitem[0]", JSON.stringify(this.cityitem[0]));
    await this.getCompanyList(this.sonCompany);
    this.mapDataList = [
      {
        id: 6,
        name: "浙江中峪农业发展有限责任公司",
        value: [120.455038, 30.110526, 1],
        adminCode: 330603,
        city: "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室",
        itemStyle: { color: "#ffc000", borderColor: "#ffc000" },
        companyDetails:
          "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工...",

        number: "400-185-3168",
        label: {
          show: false,
        },
        engineer: "hangzhou_test",
      },
      {
        id: 1,
        name: "浙江中峪日用品交易市场有限公司",
        value: [120.486645, 30.110855, 1],
        adminCode: 330603,
        city: "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-2室",
        itemStyle: { color: "#f05631", borderColor: "#f05631" },
        companyDetails:
          "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需...",
        number: "400-185-3168",
        label: {
          show: false,
        },
        engineer: "wenzhou_test",
      },
      {
        id: 3,
        name: "绍兴中峪职业技能培训学校有限公司",
        value: [120.50114, 30.090647, 1],
        adminCode: 330603,
        city: "浙江省绍兴市柯桥区柯桥街道世界贸易中心（南区）16-0207室",
        itemStyle: { color: "#ffc000", borderColor: "#ffc000" },
        companyDetails:
          "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
        number: "400-185-3168",
        label: {
          show: false,
        },
        engineer: "shaoxing_test",
      },
      {
        id: "type",
        name: "中峪道地药材平顶山仿野生白术种植基地",
        value: [121.020489, 29.385847, 1],
        adminCode: 330900,
        itemStyle: { color: "#ff7300", borderColor: "#ff7300" },
        type: 0,
        companyDetails:
          "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时...",
        city: "浙江新昌县沃洲镇黄坛村",
        number: "400-185-3168",
        label: {
          show: false,
        },
        engineer: "zhoushan_test",
      },
    ];
  },
  methods: {
    toPage(url) {
      window.open("https://" + url, "_blank");
    },
    //获取all区公司
    async getAllCompanyArea(list, mapData, param, es) {
      this.Arealist = list;
      this.AreamapData = mapData;
      this.Areaparam = param;
      this.Areaes = es;
      console.log("getAllCompanyArea", this.deep, param, es);
      if (this.deep == 1) {
        this.deep = 2;
      }
      // this.deep = 2;
      this.nameStr3 = this.Areaparam.name;
      console.log(list, param, mapData, es, "22232");

      this.cityList = [];
      await list.forEach(async (e) => {
        console.log(e);
        if (e.adCode == es.cityCode) {
          const data = { current: 1, size: 10000, pid: e.companyId };
          await getCompanyList(data).then((res) => {
            res.data.list.forEach((x) => {
              x.phone = "400-185-3168";
              x.value = JSON.parse(x.lxy);
              x.name = x.companyName;
              this.codeList2.push(x.adCode);
              this.cityList.push(x);
            });
          });
        }
      });
      const setlist = new Set(this.codeList2);
      this.codeList2 = Array(...setlist);
      this.cityitem = this.cityList;
      console.log(this.cityList[0]);
      // this.getGeoJson(es.cityCode);

      // console.log(this.cityList[0], this.codeList1);
      // console.log(param, this.cityList[0], "99591");
    },
    //获取all市公司
    async getAllCompany(list, param, type, es) {
      console.log("getAllCompany", this.deep, param, es);
      this.MapCityId = es;
      if (!this.param) {
        this.param = param;
      }
      if (this.deep == 0) {
        this.deep = 1;
      }
      this.nameStr2 = this.param.name;

      console.log(list, "22222");
      if (!type) {
        list.shift();
      }
      this.cityList = [];
      await list.forEach(async (e) => {
        const data = { current: 1, size: 10000, pid: e.companyId };
        await getCompanyList(data).then((res) => {
          res.data.list.forEach((x) => {
            x.phone = "400-185-3168";
            x.value = JSON.parse(x.lxy);
            x.name = x.companyName;
            this.codeList1.push(x.adCode);
            this.cityList.push(x);
          });
        });
      });
      const setlist = new Set(this.codeList1);
      this.codeList1 = Array(...setlist);
      this.cityList = this.cityList;
      this.cityitem = this.cityList;
      console.log(this.cityList, this.cityitem[0], es);
      // this.getGeoJson(es.cityCode);

      // console.log(this.cityList[0], this.codeList1);
      // console.log(param, this.cityList[0], "99591");
      // if (param) {
      //   if (this.cityList[0].adCode.slice(-3) == "000") {
      //     this.deep = 1;
      //     this.nameStr2 = param.name;
      //     return;
      //   } else if (this.cityList[0].adCode.slice(-2) == "00") {
      //     this.deep = 2;
      //     this.nameStr3 = param.name;
      //     return;
      //   } else if (this.cityList[0].adCode.slice(-1) == "0") {
      //     this.deep = 3;
      //   }
      // }
    },
    //获取省公司
    async getCompanyList(data, param, type) {
      console.log("getCompanyList", this.deep, param);
      let dataS = null;
      await getCompanyList(data).then((res) => {
        const data1 = res.data.list;
        data1.forEach((e) => {
          e.phone = "400-185-3168";
          e.value = JSON.parse(e.lxy);
          e.name = e.companyName;
          this.codeList.push(e.adCode);
        });

        dataS = data1;
      });
      const setlist = new Set(this.codeList);
      this.codeList = Array(...setlist);
      console.log(this.codeList, param);
      if (param) {
        this.cityList = dataS;
        this.cityitem = dataS;
      } else {
        this.cityList.push(...dataS);
        this.cityitem.push(...dataS);
      }
      // console.log(this.codeList, this.cityList);

      if (!type) {
        // this.getGeoJson(this.cityList[0].adCode);
      }

      if (param) {
        if (this.cityList[0].adCode.slice(-3) == "000") {
          this.deep = 1;
          this.nameStr2 = param.name;
          return;
        } else if (this.cityList[0].adCode.slice(-2) == "00") {
          this.deep = 2;
          return;
        } else if (this.cityList[0].adCode.slice(-1) == "0") {
          this.deep = 3;
        }
      }
      console.log(this.cityList, this.deep, this.nameStr1, param, "9951");
    },
    async getGeoJson(adcode) {
      if (this.PropsCitylist) {
        this.cityList = [];
      }
      this.PropsCitylist.forEach((e) => {
        if (e.adCode) {
          e.value = JSON.parse(e.lxy);
          e.name = e.companyName;
          this.cityList.push(e);
        }
      });
      console.log(this.cityList, "eeeeeeeeeeeeeeeeeeeeeee");
      let that = this;
      await AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          if (error) {
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData(adcode);
        });
      });
    },
    getMapData(item) {
      console.log(item);
      let mapData = this.geoJson.features.map((item) => {
        let value = 10;
        return {
          name: item.properties.name,
          value: [item.properties.center[0], item.properties.center[1], value],
          cityCode: item.properties.adcode,
        };
      });
      mapData = mapData.sort(function (a, b) {
        return a.value[2] - b.value[2];
      });
      this.mapData = mapData;

      this.mapData = mapData; //去渲染echarts
      this.initEcharts(mapData, item);
    },
    async initEcharts(mapData, item) {
      let that = this;
      this.myChart = echarts.init(this.$refs.dotMap);
      this.myChart.on("click", function (param) {
        // console.log(param, mapData, item, "112");
        if (that.deep == 0) {
          console.log(that.deep, "000000000000000000000000000");
          that.codeList.forEach(async (ent) => {
            mapData.forEach(async (e) => {
              if (e.cityCode == ent) {
                if (e.name == param.name) {
                  console.log(e);
                  that.onCity = e;
                  await that.getAllCompany(that.cityList, param, null, e);
                  await that.getGeoJson(e.cityCode);
                }
                return;
              }
            });
          });
        }
        if (that.deep == 1) {
          console.log(that.deep, "111111111111111111111111111111111");
          that.codeList1.forEach((ent) => {
            mapData.forEach((e) => {
              if (e.cityCode == ent) {
                if (e.name == param.name) {
                  console.log(e);
                  that.onCity = e;
                  console.log(that.cityList, "77515");
                  that.getAllCompanyArea(that.cityList, mapData, param, e);
                  that.getGeoJson(e.cityCode);
                }
                return;
              }
            });
          });
        }
        if (that.deep == 2) {
          console.log(
            that.deep,
            that.Arealist,
            "222222222222222222222222222222"
          );
          that.getAllCompanyArea(
            that.Arealist,
            that.AreamapData,
            that.Areaparam,
            that.Areaes
          );
          // that.getGeoJson(e.cityCode);
        }
      });
      let option = {
        tooltip: {
          trigger: "always",
          enterable: true,
          triggerOn: "click",
          position: "bottom",
          confine: true,
          textStyle: {
            ellipsis: "...",
          },
        },
        title: {
          show: false,
          left: "center",
          top: "15",
          text: this.parentInfo[this.parentInfo.length - 1].cityName,
          textStyle: {
            color: this.Type == 0 || this.Type == 1 ? "#666666" : "#B2C3FF",
            fontSize: 16,
          },
        },
        toolbox: {
          feature: {
            dataView: {
              show: false,
            },
            magicType: {
              show: false,
            },
            restore: {
              show: false,
            },
          },

          iconStyle: {
            normal: {
              borderColor: "#F72000",
            },
          },
          itemSize: 15,
          top: 20,
          right: 22,
        },
        geo: {
          show: true,
          map: "Map",
          //禁止拖动
          roam: false,
          itemStyle: {
            normal: {
              show: true,
              areaColor: "#F8FFF6",
              borderColor: "#ABCBA6",
              borderWidth: "0.3", //边框厚度
              shadowColor: "#E1F0DC", // 阴影色
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 15,
            },
            emphasis: {
              show: true,
              areaColor: "#F8FFF6",
              borderColor: "#ABCBA6",
              borderWidth: "0.6", //边框厚度
              shadowColor: "#E1F0DC", // 阴影色
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 65,
            },
          },
          label: {
            normal: {
              show: true,
              color: "#234318",
              formatter: (p) => {
                switch (p.name) {
                  case "内蒙古自治区":
                    p.name = "内蒙古";
                    break;
                  case "西藏自治区":
                    p.name = "西藏";
                    break;
                  case "新疆维吾尔自治区":
                    p.name = "新疆";
                    break;
                  case "宁夏回族自治区":
                    p.name = "宁夏";
                    break;
                  case "广西壮族自治区":
                    p.name = "广西";
                    break;
                  case "香港特别行政区":
                    p.name = "香港";
                    break;
                  case "澳门特别行政区":
                    p.name = "澳门";
                    break;
                  default:
                    break;
                }
                return p.name;
              },
            },

            emphasis: {
              show: true,
              color: "#234318",
              focus: "none",
            },
          },
          tooltip: {
            show: false,
            trigger: "none",
          },
          zoom: 1,
        },
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo", //坐标系统
            data: this.cityList,
            //地图点的样式；颜色，大小
            symbolSize: 12,
            itemStyle: {
              color: "#f4ca71",
              shadowBlur: 2,
              shadowColor: "#fc5531",
            },
          },
          {
            type: "scatter",
            coordinateSystem: "geo", //坐标系统
            data: this.cityList,
            //地图点的样式；颜色，大小
            symbolSize: 6,
            itemStyle: {
              color: "#ee1d23",
              shadowBlur: 2,
              shadowColor: "#fc5531",
            },
          },
        ],
      };
      echarts.registerMap("Map", this.geoJson); //注册
      this.myChart.setOption(option, true);
      this.option = option;
    },
    changeCityList(index) {
      this.cityitem = this.cityList[index];
      console.log(this.cityitem);
    },
  },
  beforeDestroy() {},
  watch: {
    async deep(val) {
      console.log(val);
      if (val == 0) {
        // this.deep = 0;
        this.nameStr1 = "";
        this.nameStr2 = "";
        this.nameStr3 = "";
        this.cityitem = [];
        this.cityList = [];
        this.cityList.unshift(JSON.parse(localStorage.getItem("cityitem[0]")));
        this.cityitem.unshift(JSON.parse(localStorage.getItem("cityitem[0]")));
        this.getCompanyList(this.sonCompany);
        this.getGeoJson(100000);
      } else if (val == 1) {
        // this.deep = 1;
        this.nameStr1 = "中国";
        // this.nameStr2 = "浙江省";
        this.nameStr3 = "";
        // let list = [];
        console.log(this.sonCompany);
        getCompanyList(this.sonCompany).then(async (r) => {
          console.log(r.data.list);
          // list.push(...r.data.list);
          this.getAllCompany(r.data.list, "", "pa", this.MapCityId);
          this.getGeoJson(this.MapCityId.cityCode);
        });
      } else if (val == 2) {
        // this.deep = 2;
        console.log(this.Areaes);
        this.getAllCompanyArea(
          this.Arealist,
          this.AreamapData,
          this.Areaparam,
          this.Areaes
        );
        // this.nameStr3 = " 绍兴市";
      } else {
        console.log(this.deep, "deep");
        console.log(
          this.PropsCitylist,
          this.fatherCityList,
          "9999999999999999999"
        );
        if (this.fatherCityList) {
          setTimeout(() => {
            this.getGeoJson(this.fatherCityList.adCode);
          }, 600);
        } else {
          setTimeout(() => {
            this.getGeoJson(this.adminCode);
          }, 600);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 1920px;
  height: 880px;
  margin: 0 auto;
  position: relative;
  transform: translateY(-80px);

  .dotMap {
    width: 1300px;
    height: 1000px;
  }

  .dotMap_pointer1 {
    position: absolute;
    color: #ff1400;
    z-index: 4;
    cursor: pointer;
  }

  .dotMap_pointer2,
  .dotMap_pointer3,
  .dotMap_pointer4,
  .dotMap_pointer5 {
    position: absolute;
    background-color: #1b7c2b;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 40px;
    cursor: pointer;
    z-index: 3;
  }

  .dotMap_pointer1_location {
    top: 450px;
    right: 1030px;
  }

  .dotMap_pointer_location_headquarters1 {
    top: 465px;
    right: 1000px;
  }

  .dotMap_pointer2_location {
    top: 300px;
    left: 620px;
  }

  .dotMap_pointer_location_headquarters2 {
    top: 310px;
    left: 660px;
  }

  .dotMap_pointer3_location {
    top: 210px;
    left: 540px;
  }

  .dotMap_pointer_location_headquarters3 {
    top: 240px;
    left: 600px;
  }

  .dotMap_pointer4_location {
    top: 650px;
    left: 850px;
  }

  .map_right {
    position: absolute;
    top: 150px;
    right: 350px;

    .cityList_return {
      font-size: 16px;
      padding: 25px 0;

      span {
        display: inline-block;
        cursor: pointer;
      }

      .fontbold {
        font-weight: bold;
      }
    }

    .cityList {
      padding: 0 0 0 25px;
      background: #ffffff;
      box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.11);
      font-weight: 400;
      font-size: 16px;
      color: #6b766a;
      line-height: 35px;
      cursor: pointer;
      overflow-y: auto;
      height: 400px;

      .city_item {
        margin: 30px 0;
        padding-right: 25px;
      }

      .cityList_title {
        font-size: 22px;
        color: #000000;
        margin: 15px 0;
      }
    }
  }
}

/deep/ .el-scrollbar__bar.is-vertical {
  opacity: 1;
}

/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/.is-horizontal {
  display: none;
}
</style>
